<template>
    <BaseModal
      name="modal-email-exceded"
      id="modal-email-exceded"
      idModal="modal-email-exceded"
      size="lg"
      title="Temos um problema..."
    >
      <div class="container-flex">
        <div class="text-center align-center">
          <div><img src="@/assets/img/icons/alert.svg" /></div>
          <p>
            <b>Sua taxa de e-mails foi excedida, mas não se preucupe, seu e-mail foi salvo como rascunho</b> <br /><br />
            Por favor, entrar em contato com o financeiro
          </p>
        </div>
      </div>
  
      <template v-slot:footer="{}">
        <BaseButton variant="link-info" class="mr-4" @click="closeModal">
          Fechar
        </BaseButton>
        <BaseButton variant="primary" class="mr-4" @click="hireResource">
          Entrar em contato
        </BaseButton>
      </template>
    </BaseModal>
  </template>
  
  <script>
  export default {
    data() {
      return {
        recurso: 0,
      };
    },
  
    methods: {
      closeModal() {
        this.$bvModal.hide("modal-email-exceded");
      },
      hireResource() {
        $crisp.push(["set", "session:segments", [["financeiro"]]]);
        $crisp.push(["do", "chat:open"]);
        $crisp.push([
          "do",
          "message:send",
          ["text", `Preciso resolver meus problemas financeiros.`],
        ]);
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .container-flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container-flex div {
    display: flex;
    margin-bottom: 0.5em;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    div {
      width: 35%;
      height: 35%;
    }
  }
  .text-center {
    text-align: center;
    gap: 10px;
  }
  
  .align-center {
    align-items: center;
  }
  </style>
  