<template>
  <div class="broadcast-html container">
    <wizard />
  </div>
</template>

<script>
import Wizard from "@/components/broadcast/Wizard.vue";

export default {
  components: {
    Wizard,
  },
};
</script>

<style lang="scss">
.broadcast-html {
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

  #bar {
    color: #fff;
    display: flex;
    top: 25px;
    position: relative;
  }

  .daterangepicker td.active,
  .daterangepicker td.active:hover {
    background-color: var(--greenn) !important;
  }

  #modal-list .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--greenn);
  }

  #modal-list .modal-body {
    padding: 10px;
  }

  #modal-list .modal-dialog.modal-md {
    max-width: 1000px;
  }

  #modal-list .nav-link {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--gray01);
  }

  #trasmicao
    .daterangepicker.dropdown-menu.ltr.show-calendar.show-ranges.show-weeknumbers.openscenter.linked {
    margin-left: 186px;
    margin-top: 22px;
  }

  #modal-list .modal-header {
    border: none !important;
  }

  #remetente select.select-filter-options.custom-select {
    // background-color: var(--greenn2);
    // border: 1px solid var(--greenn);
    border: 1px solid #ededf0;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 10px;
    width: 388px;
    height: 55px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
  }

  #remetente select.select-filter-options.custom-select:focus {
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
  }

  #remetente select.select-filter-options.custom-select:hover {
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
  }

  select#select-teste-ab {
    height: 50px;
    border: 1px solid #ededf0;
    background-color: #fff;
    box-sizing: border-box;
    width: 95%;
    border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
  }

  select#select-teste-ab:focus {
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
  }

  select#select-teste-ab:hover {
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
  }

  #modal-remetente {
    position: fixed;
    width: 370px;
    height: 400px;
    left: 74%;
    top: 9%;
  }

  #modal-remetente .addremetenteinput {
    width: 280px;
    height: 40px;
    border: 0.5px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 3px;
    margin-bottom: 20px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
  }

  #modal-remetente button.close {
    color: #000 !important;
    opacity: inherit;
  }

  #modal-remetente .modal-content {
    width: 315px;
    height: 90% !important;
    margin: inherit;
    margin-right: 4% !important;
  }

  #modal-remetente .modal-header {
    border: none;
    margin-bottom: -15px;
  }

  #trasmicao .multiselect.input-maior-select .multiselect__tags {
    width: 369px;
  }

  #trasmicao .multiselect__tags {
    width: 335px;
    padding-top: 11px;
    border: 1px solid #ededf0;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.2px;
    color: #000;
    font-size: 13px;
  }

  #trasmicao .multiselect__tags:hover {
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
  }

  #trasmicao .multiselect__tags:focus {
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
  }

  #automacao .multiselect__tags {
    width: 315px;
    padding-top: 11px;
    border: 1px solid #ededf0;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.2px;
    color: #000;
    height: 50px;
    font-size: 13px;
  }

  div#modal-preview {
    width: 100%;
    height: 100%;
  }

  #modal-list thead {
    background: #f5f2fc80;
  }

  #modal-list .table thead th {
    border: none;
  }

  #modal-list .table-sm th,
  .table-sm td {
    border: none;
    padding-top: 10px;
  }

  #modal-preview .modal-dialog.modal-md {
    width: 466px !important;
    height: 565px !important;
    position: relative !important;
    top: 46% !important;
    -webkit-transform: translateY(-54%) !important;
    transform: translateY(-51%) !important;
  }

  #modal-preview .modal-header {
    border: none;
  }

  #modal-preview .modal-content {
    height: 89% !important;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  #automacao .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: var(--greenn);
    background-color: var(--greenn);
  }

  #automacao .multiselect__tags:hover {
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
  }

  #automacao .multiselect__tags:focus {
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
  }

  #trasmicao select.select-filter-options.custom-select {
    border: 1px solid #ededf0;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.2px;
    color: #000;
    height: 50px;
    font-size: 13px;
    width: 330px;
    display: block;
  }

  #automacao input.input-auto-style.input-select-action.form-control {
    border: 1px solid #ededf0;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.2px;
    color: #000;
    height: 50px;
    font-size: 13px;
    background-color: #fff;
  }

  #automacao input.input-auto-style.input-select-action.form-control:hover {
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
  }

  #automacao input.input-auto-style.input-select-action.form-control:focus {
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
  }

  #automacao input.input-auto-style.input-number-auto.form-control {
    border: 1px solid #ededf0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.2px;
    color: #000;
    height: 50px;
    font-size: 13px;
    background-color: #fff;
  }

  #automacao textarea.input-auto-style.form-control {
    border: 1px solid #ededf0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.2px;
    color: #000;
    height: 50px;
    font-size: 13px;
    background-color: #fff;
    width: 280px;
  }

  #automacao input.input-auto-style.input-number-auto.form-control:hover {
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
  }

  #automacao input.input-auto-style.input-number-auto.form-control:focus {
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
  }

  #trasmicao .vue-slider-process {
    background-color: var(--greenn);
    border-radius: 15px;
  }

  #trasmicao .vue-slider-dot-handle {
    cursor: pointer;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    -webkit-box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32);
    box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32);
    background: #ccfaec;
    border: 1px solid var(--greenn);
    box-sizing: border-box;
    border-radius: 50%;
  }

  #trasmicao .vue-slider-dot-tooltip-inner {
    font-size: 12px;
    white-space: nowrap;
    padding: 2px 5px;
    min-width: 20px;
    text-align: center;
    color: #000;
    border-radius: 5px;
    border-color: #fff;
    background-color: #fff;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    margin-bottom: -5px;
  }

  #trasmicao .multiselect__select {
    top: 7px !important;
  }

  #trasmicao .multiselect__input,
  .multiselect__single {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000;
  }

  #trasmicao .form-control.reportrange-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000;
    height: 50px;
    border: 1px solid #ededf0;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 16px;
  }

  #trasmicao .form-control.reportrange-text:hover {
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
  }

  #trasmicao .form-control.reportrange-text:focus {
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
  }

  #trasmicao .multiselect__content-wrapper {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000;
  }

  #trasmicao .multiselect__placeholder {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000;
  }

  #automacao .multiselect__content-wrapper {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000;
  }

  #automacao .multiselect__placeholder {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000;
  }

  #trasmicao select.select-filter-options.custom-select:hover {
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
  }

  #trasmicao select.select-filter-options.custom-select:focus {
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
  }

  #envio .form-control.reportrange-text {
    display: block;
    margin-top: 0px;
    width: 221px;
    border: 1px solid #ededf0;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    height: 50px;
    font-size: 13px;
    letter-spacing: 0.2px;
    color: #000;
    padding: 15px;
  }

  #envio .form-control.reportrange-text:hover {
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
  }

  #envio .form-control.reportrange-text:focus {
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
  }
}
</style>
