import EmailDomainService from "@/services/resources/EmailDomainService.js";
const EmailDomain = EmailDomainService.build();

export function listaApontamentoSes(instance, domain) {
  instance.verifyLoading = true;
  EmailDomain.post("/get-identify-ses", { domain }).then((dados) => {
    instance.verifyLoading = false;
    let html = "";
    const cnameDns = dados.cname.dns ?? dados.cname;
    let dkim1 = cnameDns.dkim1;
    let dkim2 = cnameDns.dkim2;
    let mailCname = cnameDns.mail_cname;
    html += dados.amazon
      ? `
                    <tr>
                        <th>${dados.amazon.txt.name}</th>
                        <td>${dados.amazon.txt.type}</td>
                        <td>${dados.amazon.txt.value}</td>
                    </tr>
                `
      : ``;
    html += dados.amazon
      ? `
                    <tr>
                        <th>${dados.amazon.spf.name}</th>
                        <td>${dados.amazon.spf.type}</td>
                        <td>${dados.amazon.spf.value}</td>
                    </tr>
                `
      : ``;
    html += `
                <tr>
                    <th>${dados.spf.name}</th>
                    <td>${dados.spf.type.toUpperCase()}</td>
                    <td>${dados.spf.value}</td>
                </tr>
            `;
    (dados.amazon ? dados.amazon.cname : []).forEach((item) => {
      html += `
                    <tr>
                        <th>${item.name}</th>
                        <td>${item.type}</td>
                        <td>${item.value}</td>
                    </tr>
                `;
    });
    html += dados.cname
      ? `
                    <tr>
                        <th>${dkim1 ? dkim1.name : cnameDns[0].name}</th>
                        <td>${
                          dkim1 ? dkim1.type.toUpperCase() : cnameDns[0].type
                        }</td>
                        <td>${dkim1 ? dkim1.dados : cnameDns[0].value}</td>
                    </tr>
                `
      : ``;
    html += dados.cname
      ? `
                    <tr>
                        <th>${dkim2 ? dkim2.host : cnameDns[1].name}</th>
                        <td>${
                          dkim2 ? dkim2.type.toUpperCase() : cnameDns[1].type
                        }</td>
                        <td>${dkim2 ? dkim2.data : cnameDns[1].value}</td>
                    </tr>
                  `
      : ``;

    html += dados.cname
      ? `
                      <tr>
                          <th>${
                            mailCname ? mailCname.host : cnameDns[2].name
                          }</th>
                          <td>${
                            mailCname
                              ? mailCname.type.toUpperCase()
                              : cnameDns[2].type
                          }</td>
                          <td>${
                            mailCname ? mailCname.data : cnameDns[2].value
                          }</td>
                      </tr>
                  `
      : ``;
    instance
      .$swal({
        icon: "success",
        title: "Realize os seguintes apontamentos",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Já apontei",
        cancelButtonText: "Voltar",
        allowOutsideClick: true,
        width: "auto",
        confirmButtonClass: "button button-primary mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
        html: `
        <style>
          .styled-table {
            border-collapse: collapse;
            margin: 25px 0;
            font-size: 18px;
            text-align: left;
            width: 100%;
            border-radius: 10px; /* Adicionando border-radius */
            overflow: hidden; /* Para garantir que o border-radius seja aplicado corretamente */
          }
          .styled-table thead tr {
            background-color: var(--greenn2);
            color: white;
          }
          .styled-table th,
          .styled-table td {
            padding: 12px 15px;
          }
          .styled-table tbody tr {
            border-bottom: 1px solid var(--white-medium);
          }
          .styled-table tbody tr:last-of-type {
            border-bottom: 2px solid var(--white-medium);
          }
        </style>
        <table style="z-index: 999999 !important;" class="styled-table">
          <thead>
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Tipo</th>
              <th scope="col">Valor</th>
            </tr>
          </thead>
          <tbody>${html}</tbody>
        </table>
      `,
      })
      .then((result) => {
        if (result.isConfirmed) {
          instance.domainFinishSes(domain);
        }
      })
      .finally(() => {
        instance.$bvModal.hide("verifyAddress");
        instance.verifyLoading = false;
      });
  });
}
