import Rest from '@/services/Rest';

/**
 * @typedef {EmailDomainService}
 */
export default class EmailDomainService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'email-domain'


}